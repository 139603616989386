import React from 'react';
import styled from 'styled-components';
import useFormsPage from '../utils/useFormsPageQuery';
import notNull from '../utils/notNull';
import { Viewport } from '../Settings';

const Container = styled.div`
    display: grid;
    padding: 0 calc(3 / 24 * 100vw);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: calc(1 / 24 * 100vw);
    grid-row-gap: calc(1 / 24 * 100vw);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        padding: 0 var(--sizeGutters);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        padding: 0 var(--sizeGutters);
        grid-template-columns: 100%;
    }
`;

const Item = styled.div`
    border: 3px solid var(--foregroundColor);
    height: 100%;
    padding: var(--sizeGutters);
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size4);

    @media (max-width: ${Viewport.Mobile}px) {
        grid-row-gap: var(--size2);
    }
`;

const Title = styled.div`
    font-size: var(--size5);
    line-height: 1.38;
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Description = styled.div`
    font-size: var(--size3);
    line-height: 1.36;
    color: var(--foregroundColor);
    opacity: 0.8;

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size2);
    }
`;

const Link = styled.a`
    font-size: var(--size2);
    line-height: 1.39;
    color: var(--foregroundColor);
    align-self: flex-end;
`;

function FormLinks(): React.ReactElement {
    const { forms } = useFormsPage();
    return (
        <Container>
            {forms &&
                forms.filter(notNull).map(
                    ({
                        _key: key,
                        title,
                        description,
                        link,
                    }): React.ReactElement => (
                        <Item key={String(key)}>
                            <Inner>
                                {title && <Title>{title}</Title>}
                                {description && (
                                    <Description>{description}</Description>
                                )}
                                {link && link.url && (
                                    <Link href={link.url}>View the form</Link>
                                )}
                            </Inner>
                        </Item>
                    )
                )}
        </Container>
    );
}

export default React.memo(FormLinks);
