import { graphql, useStaticQuery } from 'gatsby';
import {
    FormsPageQuery,
    FormsPageQuery_sanityFormsPage as FormsPage,
} from '../__generated__/FormsPageQuery';

export default function useFormsPageQuery(): FormsPage {
    const query: FormsPageQuery = useStaticQuery(graphql`
        query FormsPageQuery {
            sanityFormsPage(_id: { eq: "formsPage" }) {
                pageMetadata {
                    ...PageMetadataFragment
                }
                banner {
                    ...BannerFragment
                }
                intro: _rawIntro
                outro: _rawOutro
                forms {
                    _key
                    title
                    description
                    link {
                        url
                    }
                }
            }
        }
    `);

    if (query.sanityFormsPage) {
        return query.sanityFormsPage;
    }

    throw new Error('Unfound FormsPage');
}
